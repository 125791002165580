.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  gap: 7px;
  .numberOfPeople {
    h6 {
      padding: 0;
      margin: 0;
    }
    button {
      background: #970505;
      padding: 0px;
      border: none;
      color: #fff7b0;
      margin: 2px;
    }
    button:hover {
      background: #e03206;
      color: #faf6d4;
    }
  }
  .date {
    h6 {
      padding: 0;
      margin: 0;
    }
  }
}
