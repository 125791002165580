.wrapper {
  width: 100%;
  height: 100vh;
  background-color: rebeccapurple;
  .box {
    width: 700px;
    height: 600px;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @media (max-width: 768px) {
    .box {
      width: 80%;
      height: auto;
      .content {
        font-size: 13px !important;
        h1 {
          font-size: 15px !important;
        }
      }
    }
  }
}
