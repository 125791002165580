.wrapper {
  background-image: url("../../../assets/bg/learningBG.png");
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-size: auto;
  .homeNavigate {
    padding-top: 1rem;
  }
  cursor: pointer;
  .header {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
  .body {
    width: 90%;
    margin: auto;
    height: 100%;
    margin-top: 3rem;
    background-image: url("../../../assets/bg/pageBG.png");
    background-size: contain;
    background-position: center center;
    background-blend-mode: multiply;
    .tableContent {
      width: 100%;
      margin: auto;
      padding: 3rem;
      .table {
        text-align: center;
        width: 92%;
        margin: auto;
        thead {
          background-color: rgb(206, 66, 66);
          color: rgb(26, 38, 41);
        }
        tbody {
          width: 100%;
          margin: auto;
          color: rgb(232, 250, 253);

          tr {
            width: 100%;
            color: rgb(26, 43, 46);

            td {
              width: 10rem;
              color: rgb(30, 48, 51);
            }
          }
        }
      }
    }
  }
}
@media (max-width: 800px) {
  .wrapper {
    background-size: auto;
    .header {
      display: flex;
      flex-direction: column;
      gap: -1px;
    }
  }
  .body {
    background-size: auto;
    .table thead {
      display: none;
    }

    .table,
    .table tbody,
    .table tr,
    .table td {
      display: block;
      width: 100%;
      text-align: center !important;
    }
    .table tr {
      margin-bottom: 35px;
    }
    .table td {
      width: 100% !important;
      margin: auto;
      position: relative;
    }
    .table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-size: 15px;
      font-weight: bold;
      text-align: left;
    }
  }
}
