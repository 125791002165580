.wrapper {
  width: 100%;
  background-image: url("../../../assets/bg/learningBG.png");
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-size: auto;
  .navArea {
    margin-top: 1rem;
  }
  .textArea {
    width: 50%;
    margin: auto;

    h1 {
      font-size: 5rem;
      text-align: center;
      color: rgb(252, 227, 227);
      cursor: pointer;
      transition: 0.5s all ease-in-out;
    }
    h1:hover {
      color: #ffe7cb;
    }
    h6 {
      text-align: center;
      color: white;
    }
    h6:hover {
      color: #ff4800;
    }
  }
  .contentArea {
    margin: auto;
    padding-top: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    .content {
      background-image: url(../../../assets/img/resources/resource.png);
      background-size: cover;
      width: 350px;
      height: 150px;
      .contentText {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        h1 {
          color: rebeccapurple;
          cursor: pointer;
          font-size: 1.8rem;
          margin-top: 3.5rem;
        }
        h1:hover {
          color: #f30808;
        }
      }
      .contentText2 {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        h1 {
          color: rebeccapurple;
          cursor: pointer;
          font-size: 1.8rem;
          margin-top: 2.6rem;
        }
        h1:hover {
          color: #f30808;
        }
      }
    }
  }
}
