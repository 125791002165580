.wrapper {
  width: 100%;
  background-image: url("../../../assets/bg/learningBG.png");
  position: absolute;
  cursor: pointer;
  min-width: 100%;
  min-height: 100%;
  background-size: auto;
  .navArea {
    padding-top: 1rem;
  }
  .body {
    width: 90%;
    margin: auto;
    height: 100%;
    margin-top: 3rem;
    background-image: url("../../../assets/bg/pageBG.png");
    background-size: contain;
    background-position: center center;
    background-blend-mode: multiply;
    color: rgb(61, 17, 14);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .upperBody {
      display: flex;
      justify-content: space-between !important;
      align-items: center;
      padding: 2rem 6rem;
      width: 90%;
      margin: auto;
      gap: 15px;

      .upperBodyLeft {
        img {
          width: 20rem;
          transition: all ease-in-out 0.2s;
        }

      }
      .upperBodyRight {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        flex-basis: 80%;
        align-items: center;
        div {
          width: 100%;
        }
        h1 {
          text-align: center;
          font-size: 45px !important;
          width: 71%;
          margin: auto;
          font-weight: bold;
        }
        h2 {
          text-align: center;
          font-size: 30px;
          font-weight: bold;
        }
        h6 {
          text-align: center;
          font-size: 20px;
          width: 89%;
          margin: auto;
        }
      }
    }
    .middleBody {
      display: flex;
      justify-content: space-between !important;
      align-items: center;
      padding: 2rem 2rem;
      gap: 15px;
      width: 90%;
      margin: auto;
      .middleLeft {
        img {
          width: 20rem;
          transition: all ease-in-out 0.3s;
        }

      }
      .middleRight {
        img {
          width: 20rem;
          transition: all ease-in-out 0.3s;
        }
      }
      .middleMiddle {
        .text1 {
          h1 {
            text-align: center;
            font-weight: bold;
          }
          h6 {
            text-align: center;
            font-weight: bold;
            margin: 2px;
            padding: 0;
          }
        }
        .text2 {
          h1 {
            text-align: center;
            font-weight: bold;
          }
          h6 {
            text-align: center;
            font-weight: bold;
            margin: 2px;
            padding: 0;
          }
        }
      }
    }
    .bottomBody {
      display: flex;
      justify-content: space-between !important;

      padding: 1rem 6rem;
      width: 80%;
      margin: auto;
      gap: 70px;
      .bottomBodyLeft {
        h4 {
          text-align: center;
        }
        span {
          font-size: 2rem;
          text-align: center;
          color: red;
          font-weight: 700 !important;
          font-style: italic;
          text-decoration: underline;
        }
      }
      .bottomBodyRight {
        margin-top: 14px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h4 {
          text-align: center;
        }
        .checkoutArea {
          .checkout {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
        }
      }
    }
    .payment {
      margin-bottom: 3rem;
      button {
        width: 7rem;
        padding: 1rem;
        background: linear-gradient(179deg, #9f2121, #180404);
        border-radius: 2rem;
        border: none;
        color: whitesmoke;
        transition: 0.3s all ease-in-out;
      }
      button:hover {
        box-shadow: 0 0px 9px 2px red;
      }
    }
  }
}

@media (max-width: 800px) {
  .wrapper {
    .body {
      .upperBody {
        flex-direction: column;
        .upperBodyRight {
          h1 {
            font-size: 25px !important;
          }
          h2 {
            font-size: 15px;
          }
        }

        h6 {
          font-size: 1rem !important;
        }
      }
      .middleBody {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .bottomBody {
        flex-direction: column;
        width: 90%;
        .bottomBodyLeft {
          text-align: center;
          span {
            font-size: 20px !important;
            margin-bottom: 1rem !important;
          }
          h4 {
            font-size: 16px;
          }
        }
        .bottomBodyRight {
          text-align: center;
          h4 {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .middleBody {
    padding: 2rem 0 !important;
    .middleLeft {
      img {
        width: 13rem !important;
      }
    }
    .middleRight {
      img {
        width: 13rem !important;
      }
    }
  }
  .upperBody {
    padding: 2rem 0 !important;
    .upperBodyLeft {
      img {
        width: 13rem !important;
      }
    }
  }
  .bottomBody {
    padding: 2rem 1rem !important;
  }
}
