.wrapper {
  width: 100%;
  height: 100vh !important;
  position: relative;
  font-family: "MedievalSharp", cursive;
  background-image: url(../../assets/bg/wood.png);
}

.bgImage {
  width: 100%;
  position: absolute;
  z-index: -1;
}

.textpart {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 85vh;
}

.imageText {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  // margin-left: 30px;
  margin-top: 5px;

  @keyframes floating {
    0% {
      transform: translate(0, 0px);
    }

    50% {
      transform: translate(0, 15px);
    }

    100% {
      transform: translate(0, -0px);
    }
  }

  img {
    width: 25rem;
    margin: auto;
    cursor: pointer;
  }
}

.imageText:hover {
  box-shadow: 0 0 4px solid yellow;
}

@media (max-width: 800px) {
  .wrapper {
    padding-top: 3rem;
    height: fit-content !important;
    padding-bottom: 5rem;
  }

  .textpart {
    flex-direction: column;
    padding-top: 11rem;
    padding-bottom: 10rem;
    flex-wrap: nowrap;

    .imageText {
      img {
        width: 17rem;
      }
    }
  }
}