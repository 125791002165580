.wrapper {
  width: 100%;
  background-image: url("../../../assets/bg/learningBG.png");
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-size: auto;
  color: rgb(94, 19, 19);
  .header {
    width: 80%;
    margin: auto;
    color: white;
    text-align: center;
    margin-bottom: 2rem !important;
  }
  .body {
    width: 90%;
    margin: auto;
    margin-top: 2rem;
    background-image: url("../../../assets/bg/pageBG.png");
    background-size: contain;

    margin: auto;
    .bodyContent {
      width: 80%;
      margin: auto;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
      padding: 2rem 0;
      .content {
        padding: 1rem;
        margin: auto;
        .image {
          img {
            width: 15rem;
          }
        }
        .text {
          width: 18rem;
          color: rgb(31, 8, 8);
          text-align: center;
          h3 {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .body {
    .bodyContent {
      grid-template-columns: repeat(3, 1fr) !important;
      grid-template-rows: repeat(3, 1fr) !important;
      padding: 1rem 0;
    }
  }
}
@media (max-width: 1100px) {
  .body {
    .bodyContent {
      grid-template-columns: repeat(2, 1fr) !important;
      grid-template-rows: repeat(4, 1fr) !important;
      padding: 1rem 0;
    }
  }
}
@media (max-width: 800px) {
  .navArea {
    padding-top: 1rem;
  }
  .header {
    margin-top: 1rem !important;
  }
  .body {
    .bodyContent {
      grid-template-columns: repeat(1, 1fr) !important;
      grid-template-rows: repeat(0, 1fr) !important;
      padding: 1rem 0;
      .content {
        .text {
          width: 13rem !important;
        }
        .image {
          img {
            width: 13rem !important;
          }
        }
      }
    }
  }
}
