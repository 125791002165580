.wrapper {
  width: 100%;
  background-image: url("../../../assets/bg/learningBG.png");
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-size: auto;
  padding-top: 1rem;
  .contentArea {
    width: 80%;
    margin: auto;
    margin-top: 2rem;
    background-image: url("../../../assets/bg/pageBG.png");
    background-size: contain;
    .content {
      width: 100%;
      margin: auto;
      padding: 7rem;
      justify-content: center !important;
      display: flex;

      .leftContent {
        flex-basis: 33%;
        img {
          width: 100%;
        }
      }

      .middleContent {
        flex-basis: 50%;
        padding: 0 2rem;
        h1 {
          font-size: 3rem;
          text-align: center;
        }
        h6 {
          font-size: 1.6rem;
        }
      }
      .rightContent {
        width: 70%;
        img {
          width: 100%;
        }
        .rightContentTwo {
          display: flex;
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .rightContent {
    .rightContentTwo {
      display: flex;
      flex-direction: column;
    }
  }
}
@media (max-width: 1200px) {
  .content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0rem !important;
    padding-top: 2rem !important;
    .middleContent {
      h6 {
        padding: 1rem;
        text-align: center;
      }
    }
  }
  .rightContent {
    gap: 1rem !important;
    display: flex;
    flex-direction: column;
    .rightContentOne {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .rightContentTwo {
      display: flex;
      gap: 1rem;
      padding-bottom: 2rem;
    }
  }
}
