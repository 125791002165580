.wrapper {
  background-image: url("../../../assets/bg/learningBG.png");
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-size: auto;
  cursor: pointer;
  .header {
    padding-top: 1rem;
  }
  .body {
    .textArea {
      text-align: center;
      h5 {
        width: 80%;
        padding-bottom: 2rem;
        margin: auto;
        text-align: center;
        font-style: italic;
        font-weight: 500;
        color: rgb(252, 229, 229);
      }
      h1 {
        font-size: 5rem;
        text-align: center;
        color: #fce3e3;
        cursor: pointer;

        transition: 0.5s all ease-in-out;
      }
      h1:hover {
        color: #ffe7cb;
      }
    }
    .tableArea {
      background-image: url("../../../assets/bg/pageBG.png");
      background-size: contain;

      width: 65%;
      margin: auto;

      margin: auto;
      padding: 5rem 0;
      .topTable {
        display: flex;
        gap: 2rem;
        align-items: center;
        justify-content: center;
        color: #3f0a0a;
        .tableLeft {
        }
        .table {
          width: 90%;
          margin: auto;
          tbody {
            tr {
              transition: all ease-in-out 0.2s;
            }
            tr:hover {
              color: rgb(255, 102, 0);
            }
          }
        }
      }
      .bottomTable {
        color: #3f0a0a;

        display: flex;
        gap: 3rem;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
        .tableRight {
          width: 19rem;
          height: 21rem;
        }
        .image {
          width: 100%;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 800px) {
  .wrapper {
    .header {
      flex-direction: column;
      gap: 0;
    }
    .textArea {
      width: 90%;
      margin: auto;
      margin-top: 2rem;
      h1 {
        font-size: 2rem !important;
      }
      h5 {
        font-size: 15px;
      }
    }
    .body {
      .tableArea {
        padding: 1rem 0;
        width: 95%;
        .topTable {
          flex-direction: column;
          gap: 1rem;
          .table {
            width: 85%;
            margin: auto;
            border: 1px solid black;
            tbody {
              border: 1px solid #410606;
              tr {
                border: 1px solid #410606;

                td {
                  padding: 3px;
                }
              }
            }
          }
        }
        .bottomTable {
          flex-direction: column;
          justify-content: center !important;
          gap: 1rem;
          align-items: center !important;
          padding-top: 1rem;
          .table {
            width: 85%;
            margin: auto;
            border: 1px solid black;
            tbody {
              border: 1px solid #410606;
              tr {
                border: 1px solid #410606;

                td {
                  padding: 3px;
                  width: 165px;
                }
              }
            }
          }
        }
      }
    }
  }
}
