.wrapper {
  width: 100%;
  position: absolute;
  background-image: url("../../assets/bg/learningBG.png");
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-size: auto;
  .navArea {
    margin-top: 1rem;
  }
  .textArea {
    width: 100%;
    margin: auto;
    height: fit-content;

    padding-bottom: 3rem;
    cursor: pointer;

    // h1 {
    //   font-size: 5rem;
    //   text-align: center;
    //   color: rgb(252, 227, 227);
    //   cursor: pointer;

    //   transition: 0.5s all ease-in-out;
    // }
    // h1:hover {
    //   color: rgb(255, 231, 203);
    // }
    h1 {
      color: hsl(0, 0%, 28%);
      font-size: 80px;
      font-weight: bold;
      letter-spacing: 7px;
      cursor: pointer;
      text-transform: uppercase;
      text-align: center;
    }

    h1 {
      padding: 14px;
      background: linear-gradient(
        to right,
        hsl(0, 100%, 66%) 0,
        hsl(51, 100%, 50%) 10%,
        hsl(0, 97%, 63%) 20%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: shine 3s infinite linear;
    }
    @keyframes shine {
      0% {
        background-position: 0;
      }
      60% {
        background-position: 1300px;
      }
      100% {
        background-position: 100px;
      }
    }
    h6 {
      font-size: 2rem;
      width: 60%;
      margin: auto;
      text-align: center;
      cursor: pointer;
      color: #f0e0e0;
      margin-top: 2rem;
      transition: 0.08s all ease-in-out;
    }
    h6:hover {
      color: rgb(252, 227, 199);
    }
  }
  .contentArea {
    width: 100%;
    height: fit-content;

    .mainContent {
      width: 90%;
      margin: auto;

      .content {
        margin: auto;
        width: 90%;
        margin: auto;
        margin-top: 2rem;
        display: flex;
        border: 2px solid rgba(0, 0, 0, 0);
        flex-direction: row;
        justify-content: space-around;
        height: auto;
        transition: 0.6s all ease-in-out;
        margin-bottom: 5rem;
        background-image: url("../../assets/bg/learningContent.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: center;
        border-radius: 35px;

        .contentImage {
          width: 36%;
          padding: 2rem 0;

          img {
            width: 95%;
            padding-left: 4rem;

            border: 2px solid transparent;
            transition: 0.6s all ease-in-out;
          }
          img:hover {
            width: 94%;
          }
        }

        .contentText {
          display: flex;
          flex-direction: column;
          width: 80%;
          align-items: center;
          cursor: pointer;

          justify-content: center;
          color: rgb(75, 9, 9);

          text-align: center;

          h1 {
            font-size: 35px;
            transition: 0.3s all ease-in-out;
          }
          h6 {
            font-size: 20px;
            transition: 0.3s all ease-in-out;
            width: 80%;
          }
          h1:hover {
            color: rgb(180, 82, 2);
          }
          h6:hover {
            color: rgb(180, 82, 2);
          }
        }
      }
      .content:hover {
        border: 3px solid rgba(255, 0, 0, 0.185);

        h1,
        h6 {
          color: rgb(27, 5, 49);
        }
      }
    }
  }
}

// @media (max-width: 1200px) {
//   .wrapper {
//     .contentArea {
//       .mainContent {
//         .content {
//           .contentImage {
//             width: 76%;
//             padding-left: 1rem;
//             img {
//               padding-left: 1rem;
//             }
//           }
//           .contentText {
//             width: 80%;
//             h1 {
//               font-size: 2rem;
//               margin-top: 1rem;
//             }
//             h6 {
//               font-size: 1.3rem;
//               width: 100%;
//             }
//           }
//         }
//       }
//     }
//   }
// }
@media (max-width: 800px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .navArea {
      nav {
        .wrap {
          .nbarmenu-icon {
            top: 0% !important;
          }
        }
      }
    }
    .contentArea {
      .mainContent {
        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .contentImage {
            width: 100%;
            padding-left: 1rem;
            margin: 0;
            padding: 0;
            margin-top: 2rem;
            img {
              padding-left: 1rem;
              padding-top: 5rem;
            }
          }
          .contentText {
            margin-top: 2rem;
            width: 85%;
            h1 {
              font-size: 1.2rem;
            }
            h6 {
              font-size: 15px;
              width: 100%;
              padding-bottom: 100px;
            }
          }
        }
      }
    }
    .textArea {
      padding-top: 1rem;
      padding-bottom: 0rem;
      h1 {
        font-size: 30px;
        padding-top: 0rem;
        padding: 9px;
      }
      h6 {
        font-size: 14px;
        width: 90%;
        margin: auto;
      }
    }
  }
}

// @media (max-width: 800px) {
//   .wrapper {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     padding-top: 1rem;
//     .contentArea {
//       .mainContent {
//         .content {
//           display: flex;
//           flex-direction: column;
//           align-items: center;
//           justify-content: center;

//           .contentImage {
//             width: 100%;
//             padding-left: 1rem;
//             margin: 0;
//             padding: 0;
//             img {
//               padding-left: 2rem;
//               padding-top: 6rem;
//             }
//           }
//           .contentText {
//             width: 95%;
//             margin-top: 1rem;
//             h1 {
//               font-size: 1.2rem;
//             }
//             h6 {
//               font-size: 15px;
//               width: 100%;
//               padding-bottom: 127px;
//               padding-left: 18px;
//             }
//           }
//         }
//       }
//     }
//     .textArea {
//       h1 {
//         font-size: 27px;
//         padding-top: 0rem;
//       }
//       h6 {
//         font-size: 15px;
//         width: 90%;
//         font-weight: 200;
//       }
//     }
//   }
// }
