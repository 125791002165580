.wrapper {
  background-image: url("../../../assets/bg/learningBG.png");
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  .body {
    background-image: url("../../../assets/bg/pageBG.png");
    background-size: contain;
    width: 70%;
    margin: auto;
    .video {
      padding-top: 2rem;
      width: 65%;
      margin: auto;
      display: flex;
      align-items: flex-end;
      gap: 5px;
      video {
        width: 100%;
        margin: auto;
      }
      .btn {
        padding: 8px 17px !important;
        color: white !important;
        border-radius: 19px !important;
        border: none !important;
        font-weight: bold !important;
        transition: all ease-in-out 0.3s;
        background: linear-gradient(161deg, #f55f01, #ff8100) !important;
      }
      .btn:hover {
        background: linear-gradient(161deg, #b64703, #ff6600) !important;
      }
    }
    .text {
      h4 {
        width: 80%;
        margin: auto;
        padding: 2rem 0;
        text-align: justify;
      }
    }
  }
}

@media (max-width: 800px) {
  .wrapper {
    .navArea {
      margin-top: 1rem;
    }
  }
  .body {
    width: 96% !important;
    margin-top: 4rem !important;
    .video {
      flex-direction: column;
      width: 90% !important;
      align-items: center !important;
      justify-content: center !important;
    }
  }
}
@media (max-width: 800px) {
  .text {
    h4 {
      font-size: 15px;
    }
  }
}
