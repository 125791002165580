.wrapper {
  // width: 100%;
  // background-image: url("../../../../assets/bg/learningBG.png");

  // min-width: 100%;
  // min-height: 100%;
  // background-size: auto;
  .navArea {
    margin-top: 1rem;
  }

  .container {
    // width: 90%;
    // background-image: url("../../../../assets/bg/pageBG.png");
    // background-size: cover;
    // margin: auto;
    // background-position: center, center;
    // margin-top: 2rem;
    // padding: 4rem;

    .top {
      padding-bottom: 2rem;
      padding-top: 2rem;

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        justify-content: center;

        h5 {
          width: 60%;
          text-align: center;
        }
      }
    }

    .middle {
      display: flex;
      gap: 2rem;
      align-items: self-start;
      justify-content: center;
      margin-bottom: 1rem;

      .leftpart {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .img {
          width: 12rem;
        }

        .text {
          text-align: center;
          font-size: 20px;
        }

        .paymentBtn {
          img {
            width: 8rem !important;
          }
        }
      }

      .middlepart {
        img {
          width: 25rem;
        }

      }

      .rightPart {
        width: 20%;
        text-align: center;
      }
    }

    .bottom {
      display: flex;
      gap: 2rem;
      justify-content: center;
      align-items: self-start;
      margin-top: 1rem;
      padding: 1rem 3rem 1rem 9rem;

      .bottomleft {
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .image {
          img {
            width: 15rem;
          }
        }

        .text {
          text-align: center;
          font-size: 20px;
        }
      }

      .bottomRight {
        display: flex;
        flex-direction: column;

        .text {
          width: 90%;
          text-align: center;

          span {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {

  .top {
    .content {
      h5 {
        width: 90% !important;
      }
    }
  }

  .middle {
    .leftpart {
      .img {
        width: 18rem;
      }

      .text {
        text-align: center;

        span {
          font-size: 22px;
          line-height: 20px;
        }
      }
    }

    .middlepart {
      img {
        width: 20rem !important;
      }

    }

    .rightPart {
      width: 70% !important;

      .text {

        font-size: 16px !important;
      }
    }
  }
}

@media (max-width: 1000px) {

  .middle {
    .leftpart {
      .img {
        width: 20rem;
      }

      .text {
        text-align: center;
      }
    }

    .middlepart {
      img {
        width: 15rem !important;
        font-size: 13px;
      }

    }

    .rightPart {
      .text {
        font-size: 16px;

      }
    }
  }

  .bottom {
    margin-top: 10px;
    flex-direction: column;
    padding: 0rem !important;
    .text {
      font-size: 16px !important;
    }

    .bottomleft {
      .text {
        span {
          font-size: 26px;

        }
      }
    }

    .bottomRight {
      .text {
        margin-bottom: 10px;

      }
    }
  }
}

@media (max-width: 900px) {
  .container {
    padding: 1rem !important;
  }

  .middle {
    flex-direction: column !important;
    align-items: center !important;

    .middlepart {
      .text {
        text-align: center;

        span {
          text-align: center;
          font-size: 13px;
        }

      }
    }
  }

  .bottom {
    flex-direction: column;
    align-items: center !important;

    .text {
      font-size: 13px;
      margin-top: 1rem;
    }
  }
}

@media (max-width: 600px) {
  .container {
    padding: 1rem !important;
  }

  .middle {
    flex-direction: column !important;
    align-items: center !important;

    .middlepart {
      .leftpart {
        img {
          width: 18rem !important;
        }
      }

      .text {
        text-align: center;

        span {
          text-align: center;
          font-size: 13px;
        }

      }
    }
  }

  .bottom {
    flex-direction: column;
    align-items: center !important;

    .text {
      font-size: 13px;
    }
  }
}