.wrapper {
  width: 90%;
  margin: auto;
  background-image: url("../../../../assets/bg/pageBG.png");
  background-size: cover;
  background-position: center center;
  .content {
    padding: 3rem;
    width: 90%;
    margin: auto;
    .images {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 10px;
      padding: 10px;
      img {
        width: 100%;
      }
      .imageBtn {
        img {
          border: 3px dotted ff4600;
          transition: 0.3s ease-in-out;
        }
        img:hover {
          filter: drop-shadow(0px 1px 1px rgb(235, 44, 10));
        }
      }
      h5 {
        width: 90%;
        margin: auto;
        text-align: center;
        font-size: 15px;
        margin-top: 5px;
        font-weight: bold;
      }
    }
    .text {
      width: 80%;
      margin: auto;
      text-align: center;
      margin-top: 2rem;
      h6 {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 1300px) {
  .images {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
@media (max-width: 1000px) {
  .content {
    width: 95% !important;
    padding: 0rem !important;
  }
  .images {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media (max-width: 800px) {
  .content {
    width: 95% !important;
    padding: 0rem !important;
    .images {
      grid-template-columns: repeat(1, 1fr) !important;
    }
    .text {
      width: 90% !important;
      h6 {
        font-size: 15px !important;
      }
    }
  }
}
