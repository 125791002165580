.wrapper {
  background-image: url("../../../assets/bg/learningBG.png");
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-size: auto;
  nav {
    padding-top: 1rem;
  }
  .header {
    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    font-style: italic;
    width: 80%;
    margin: auto;
    margin-top: 2rem;
    gap: 2rem;
    .headLeft {
      h1 {
        font-size: 56px;
      }
    }
    .headMiddle {
      padding: 2rem;

      h6 {
        padding: 2rem;
      }
    }
    .headRight {
      width: 24rem;
      img {
        width: 24rem;
      }
    }
  }
  .body {
    background-image: url("../../../assets/bg/pageBG.png");
    background-size: contain;
    width: 90%;
    margin: auto;
    .upperBody {
      width: 80%;
      margin: auto;
      margin-top: 3rem;
      .gridContainer {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 20px;
        padding-top: 3rem;
        .gridItem {
          img {
            width: 100%;
          }
          h1 {
            color: rgb(41, 18, 18);
            font-size: 26px;
            text-align: center;
            margin-top: 4px;
          }
        }
      }
    }
    .lowerBody {
      width: 90%;
      margin: auto;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
      padding-top: 3rem;
      .gridItem {
        display: flex;
        flex-direction: column;
        img {
          width: 90%;
        }
        h1 {
          font-size: 26px;
          text-align: center;
          margin-top: 1rem;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .wrapper {
    .header {
      flex-direction: column;
      gap: 0rem;
      .headLeft {
        h1 {
          font-size: 30px;
        }
      }
      .headMiddle {
        padding: 0rem;
        h1 {
          text-align: center;
        }
        h6 {
          text-align: center;
        }
      }
      .headRight {
        width: 20rem;
        img {
          width: 20rem;
        }
      }
    }
    .body {
      .upperBody {
        .gridContainer {
          display: flex !important;
          flex-direction: column;
        }
      }
      .lowerBody {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
@media (max-width: 600px) {
  .gridItem {
    h1 {
      font-size: 20px !important;
    }
  }
}
