.hamburgerMenu {
  display: none;
}
.nbarmenu-icon {
  display: none;
}

.wrap ul {
  font-family: "MedievalSharp", cursive;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 43%;
  height: 10rem;
  margin: auto;
}
.nbarList {
  width: 7rem;
  height: 7em;
  list-style: none;
  display: flex;
  align-items: center;
  background-image: url(../../assets/img/blood1.png);
  background-size: cover;
  background-position: center;
  text-align: center;
}
.nbarList a {
  text-decoration: none;
  width: 100%;
  color: rgb(255, 255, 255);
  text-align: center;
  line-height: 12px;
  font-size: 11px;
}
.text-link {
  color: inherit;
  text-decoration: inherit;
}
a:hover {
  color: rgb(250, 116, 6);
  text-decoration: none;
}
.nbarLink {
  color: inherit;
  text-decoration: none;
}
@media (max-width: 1000px) {
  .navElement {
    display: none;
  }
  .navElement .nbarList {
    display: none;
  }
  .navElement .nbarList a {
    font-size: 17px;
  }
}

@media (max-width: 800px) {
  .wrapper {
    display: flex;
    justify-content: space-around;
    padding-top: 2rem !important;
  }
  .nbarmenu-icon {
    display: block;
  }
  .nbar-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 64vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .nbar-menu ul {
    font-family: "MedievalSharp", cursive;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    height: 95%;
    margin: auto;
    gap: 8px;
    position: absolute;
    z-index: 999;
  }
  .nbarmenu-icon {
    position: absolute;
    left: 90%;
    z-index: 999;
  }
  .fa,
  .fas {
    font-size: 1.5rem;
    color: #ff2300f0;
    box-shadow: 0px 0px 7px 1px #5a0404;
  }

  .nbar-menu.active {
    background: #1d0303;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    max-height: 64vh;
    top: 0%;
  }
}
