.wrapper {
  background-image: url("../../../assets/bg/learningBG.png");
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  color: white;
  background-size: auto;
  cursor: pointer;
  .navArea {
    padding-top: 1rem;
  }
  .header {
    .headerContent {
      width: 90%;
      margin: auto;
      text-align: start;
      h1 {
        color: white;
        text-align: center;
        transition: 0.5s all ease-in-out;
      }
      h1:hover {
        color: #ffba9f;
      }
      h6 {
        color: white;
        text-align: center;
        transition: 0.5s all ease-in-out;
      }
      h6:hover {
        color: #ffba9f;
      }
    }
  }
  .body {
    width: 90%;
    margin: auto;
    height: 100%;
    margin-top: 3rem;
    background-image: url("../../../assets/bg/pageBG.png");
    background-size: contain;
    background-position: center center;
    background-blend-mode: multiply;
    color: rgb(61, 17, 14);
    .bodyContent {
      padding: 1rem 7rem;
      width: 100%;
      margin: auto;
      margin-top: 2rem;
      margin: auto;
      .content {
        table {
          caption-side: bottom;
          border-collapse: collapse;
          width: 100%;

          thead {
            background-color: rgb(87, 15, 15);
            color: rgb(212, 249, 250);
            padding: 2rem;
            text-align: start;
            margin-bottom: 2rem !important;
            tr {
              gap: 2rem;
              th {
                width: 33%;
              }
            }
          }
          tbody {
            tr {
              td {
                text-align: start;
              }
            }
            tr:hover {
              border: 1px solid rgb(53, 6, 6);
              background-color: rgba(121, 6, 6, 0.144);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .table thead {
    display: none;
  }

  .table,
  .table tbody,
  .table tr,
  .table td {
    display: block;
    width: 100%;
    text-align: center !important;
  }
  .table tr {
    margin-bottom: 35px;
  }
  .table td {
    width: 100% !important;
    margin: auto;
    position: relative;
  }
  .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
  }
  .header {
    .headerContent {
      width: 90%;
      margin: auto;
      text-align: start;
      padding-top: 2rem;
      h1 {
        color: white;
        text-align: center;
        font-size: 25px;
      }
      h6 {
        color: white;
        text-align: center;
        font-size: 15px;
      }
    }
  }
} ;
