.wrapper {
  width: 90%;
  margin: auto;
  background-image: url("../../../../assets/bg/pageBG.png");
  background-size: cover;
  background-position: center center;
  .mainContent {
    padding: 3rem;
    width: 90%;
    margin: auto;
    .images {
      display: flex;
      gap: 1rem;
      padding-top: 1rem;
      align-self: center;
      img {
        width: 100%;
      }
    }
    h6 {
      text-align: center;
      margin-top: 1rem;
      font-size: 20px;
    }
    .text {
      width: 80%;
      text-align: center;
      margin: auto;
      font-weight: bolder;
      padding: 2rem 0;
      h6 {
        font-size: 20px;
      }
    }
  }
}
