.wrapper {
  width: 100%;
  background-image: url("../../../assets/bg/learningBG.png");
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-size: auto;
  color: rgb(94, 19, 19);
  .header {
    padding-top: 1rem;
  }

  .body {
    .bodyTitle {
      width: 80%;
      margin: auto;
      color: antiquewhite;
      text-align: center;
      margin-top: 2rem;
      h1 {
        font-size: 2.5rem;
      }
    }
    .bodySubTitle {
      width: 80%;
      margin: auto;
      color: antiquewhite;
      font-size: 1.5rem;
      text-align: center;
    }

    .bodyTable {
      width: 90%;
      margin: auto;
      margin-top: 2rem;
      background-image: url("../../../assets/bg/pageBG.png");
      background-size: contain;

      margin: auto;

      background-position: center, center;
      .tableContent {
        margin-top: 2rem;
        padding-top: 2rem;
        .table {
          border-collapse: collapse;
          width: 89%;
          margin: auto;
          padding: 14rem;
          font-size: 2rem;
          cursor: pointer;
          tbody {
            text-align: start;
            width: 90%;
            margin: auto;
          }
          td:first-child {
            padding: 10px;
            width: 114px !important;
            height: 50px;
            border: 1px solid black;
          }
          td {
            border: 1px solid black;
            padding: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .wrapper {
    .header {
      nav {
        /* display: block; */
        position: sticky;
      }
    }
    .body {
      .bodyTitle {
        h1 {
          font-size: 1.5rem;
        }
      }
      .bodySubTitle {
        font-size: 0.9rem;
      }
      .bodyTable {
        .tableContent {
          .table {
            font-size: 1rem;
            .tbody {
            }
          }
        }
      }
    }
  }
}
