.wrapper {
  width: 90%;
  margin: auto;
  background-image: url("../../../../assets/bg/pageBG.png");
  background-size: cover;
  background-position: center center;
  .mainContent {
    padding: 3rem;
    width: 90%;
    margin: auto;
    .images {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;
      padding: 10px;
      justify-content: center;
      padding-top: 1rem;
      align-self: center;
      img {
        width: 100%;
      }
      h5 {
        width: 90%;
        margin: auto;
        text-align: center;
        font-size: 20px;
        margin-top: 5px;
        font-size: 25px;
        font-weight: 600;
      }
    }
    .text {
      width: 80%;
      text-align: center;
      margin: auto;
      font-weight: bolder;
      padding: 2rem 0;
      h6 {
        font-size: 20px;
      }
    }
  }
}
@media (max-width: 1500px) {
  .mainContent {
    padding: 0rem !important;
  }
}
@media (max-width: 1500px) {
  .mainContent {
    padding: 0rem !important;
    .imageLast {
      span {
        font-size: 17px !important;
      }
    }
  }
}
@media (max-width: 800px) {
  .mainContent {
    .images {
      grid-template-columns: repeat(1, 1fr) !important;
    }
    .text {
      h6 {
        font-size: 20px !important;
        padding-bottom: 2rem;
      }
    }
  }
}
