.wrapper {
  background-image: url("../../../assets/bg/learningBG.png");
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-size: auto;
  .mainContent {
    background-image: url("../../../assets/bg/pageBG.png");
    background-size: contain;
    width: 75%;
    margin: auto;
    .upperContent {
      width: 90%;
      margin: auto;
      .content {
        width: 100%;
        height: 100%;
        padding: 2rem 1rem;
        margin: auto;
        display: flex;

        .image {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 1rem;
          width: 100%;
          height: fit-content;
          img {
            width: 100%;
          }
          h4 {
            text-align: center;
            width: 90%;
            margin: auto;
            margin-top: 0rem;
          }
        }
      }
    }
    .downContent {
      width: 80%;
      margin: auto;
      text-align: center;
      padding: 1rem 0;
      div {
        border: 1px solid rgb(56, 31, 31);
        padding: 1rem;
      }
    }
  }
}

@media (max-width: 1000px) {
  .wrapper {
    .navArea {
      padding-top: 1rem;
    }
    .mainContent {
      margin-top: 38px;
      width: 94%;
      .content {
        flex-direction: column;
        .image {
          gap: 10px !important;
          h4 {
            margin-bottom: 10px !important;
            font-size: 13px !important;
            width: 100% !important;
          }
        }
      }
      .downContent {
        div {
          padding: 10px !important;

          h1 {
            padding: 0rem !important;
            font-size: 19px;
            font-weight: 600;
          }
          h6 {
            font-size: 14px;
          }
        }
      }
    }
  }
}
