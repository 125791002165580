.wrapper {
  position: relative;
  width: 100%;
  height: fit-content;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  overflow-y: hidden !important; /* Hide vertical scrollbar */
  overflow-x: hidden !important; /* Hide horizontal scrollbar */

  video {
    width: 100%;
    position: relative;
    object-fit: cover;
    border: 1px solid black;
  }

  // .soundbtn {
  //   .sbtn {
  //     position: absolute;
  //     z-index: 1;
  //     background: linear-gradient(to bottom, #8d0e0e, #2c0303);
  //     padding: 1rem 0;
  //     border-radius: 50px;
  //     box-shadow: 0px 3px 11px 2px rgb(172, 4, 4);
  //     left: 85%;
  //     width: 4rem;
  //     color: #ffffff;
  //     bottom: 61%;
  //     border: none;
  //     svg {
  //       width: 80px;
  //     }
  //     .icon {
  //       width: 20%;
  //       height: 20%;
  //     }
  //   }
  //   .sbtn:hover {
  //     background: linear-gradient(to bottom, #8f0d0d, #4e0505);
  //     box-shadow: 0px 7px 18px -1px rgb(184, 26, 26);
  //   }
  // }

  .soundbtn {
    height: 5%;
    position: absolute;
    bottom: 60%;
    right: 10%;
    font-family: "MedievalSharp", cursive;
    // z-index: 1;
    border: none;
    padding: 7px;

    .sbtn {
      z-index: 1;
      position: absolute;
      display: inline-block;
      width: 100px;
      height: 54px;
      background: linear-gradient(to bottom, #8d0e0e, #2c0303);
      color: #fff;
      border-radius: 50px;
      text-align: center;
      cursor: pointer;
      border: none;
      box-shadow: 0px 3px 11px 2px rgb(172, 4, 4);
    }

    .sbtn:hover {
      background: linear-gradient(to bottom, #c41010, #690606);
      box-shadow: 0px 3px 11px 2px rgb(211, 20, 20);
    }

    svg {
      height: 3rem;
      padding: 10px 18px;
      color: rgb(255, 153, 1);
    }

    .icon {
      width: 5rem;
    }
  }
}

.logoSlide {
  width: 100%;
  height: fit-content !important;
}

.lowerbar img {
  position: absolute;

  z-index: 1;
  width: 100%;
  top: 29%;
}
.navTop {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 5rem;
}

@media (max-width: 1800px) {
  .soundbtn {
    top: 33%;
    left: 83%;
  }
}
@media (max-width: 1700px) {
  .soundbtn {
    top: 32%;
    left: 81%;
  }
}
@media (max-width: 1600px) {
  .soundbtn {
    top: 31%;
    left: 80%;
  }
}
@media (max-width: 1500px) {
  .soundbtn {
    top: 29%;
    left: 79%;
  }
}
@media (max-width: 1400px) {
  .soundbtn {
    top: 27%;
    left: 79%;
  }
}
@media (max-width: 1300px) {
  .soundbtn {
    top: 25%;
    left: 81%;
  }
}
@media (max-width: 1200px) {
  .soundbtn {
    top: 23%;
    left: 81%;
    .sbtn {
      width: 80px !important;
    }
  }
}
@media (max-width: 1100px) {
  .soundbtn {
    top: 22%;
    left: 81%;
  }
}
@media (max-width: 1000px) {
  .soundbtn {
    top: 21%;
    left: 81%;
  }
}
@media (max-width: 900px) {
  .soundbtn {
    top: 17%;
    left: 80%;
  }
  .logoSlide {
    width: 100%;
    height: fit-content !important;
  }
}
@media (max-width: 800px) {
  .soundbtn {
    top: 17%;
    left: 81%;
  }
}
@media (max-width: 700px) {
  .soundbtn {
    top: 15%;
    left: 80%;
  }
}
// @media (max-width: 600px) {
//   .soundbtn {
//     top: 12%;
//     left: 79%;
//     // .sbtn {
//     //   width: 80px !important;
//     // }
//     // svg {
//     //   padding: 5px 5px !important;
//     //   height: 2rem !important;
//     // }
//   }
// }
@media (max-width: 500px) {
  .soundbtn {
    left: 80%;
    top: 15%;
    .sbtn {
      width: 50px !important;
      height: 30px !important;
    }
    svg {
      height: 2rem !important;
      width: 50px !important;
    }
  }
}
@media (max-width: 400px) {
  .soundbtn {
    left: 80%;
    top: 12%;
    .sbtn {
      width: 50px !important;
      height: 30px !important;
    }
    svg {
      height: 2rem !important;
      width: 50px !important;
    }
  }
}
// @media (max-width: 300px) {
//   .soundbtn {
//     top: 8%;
//     left: 81%;
//   }
// }
