.wrapper {
  width: 100%;
  background-image: url("../../../assets/bg/learningBG.png");
  position: absolute;
  cursor: pointer;
  min-width: 100%;
  min-height: 100%;
  background-size: auto;

  .container {

    width: 90%;
    margin: auto;
    height: 100%;
    margin-top: 3rem;
    background-image: url("../../../assets/bg/pageBG.png");
    background-size: cover;
    background-position: center center;
    background-blend-mode: multiply;
    color: rgb(61, 17, 14);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem  0 2rem 0;
  }
}