.wrapper {
  width: 100%;
  background-image: url("../../../assets/bg/learningBG.png");
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-size: auto;
  padding-top: 1rem;

  .body {
    width: 90%;
    margin: auto;
    margin-top: 2rem;
    background-image: url("../../../assets/bg/pageBG.png");
    background-size: contain;
    .content {
      width: 80%;
      margin: auto;
      padding-top: 2rem;
      .upperContent {
        width: 90%;
        justify-content: center;
        display: flex;
        align-items: center;

        margin: auto;
        gap: 2rem;
        .imageLeft {
          flex-basis: 33.33%;
          img {
            width: 100%;
          }
        }
        .imageRight {
          flex-basis: 33.33%;
          img {
            width: 100%;
          }
        }
        .text {
          flex-basis: 33.33%;
          h1 {
            text-align: center;
          }
        }
      }
      .downContent {
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        .downContentLeft {
          width: 80%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          img {
            width: 100%;
            padding: 5px;
          }
        }
        .downContentRight {
          img {
            width: 100%;
            padding-bottom: 2rem;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .upperContent {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .downContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .downContentLeft {
      display: flex !important;
      flex-direction: column !important;
    }
  }
}
