.wrapper {
  width: 100%;
  background-image: url("../../../assets/bg/learningBG.png");
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-size: auto;
  box-sizing: border-box;
  .navArea {
    margin-top: 1rem;
  }
  .textArea {
    width: 50%;
    margin: auto;

    h1 {
      font-size: 5rem;
      text-align: center;
      color: rgb(252, 227, 227);
      cursor: pointer;
      transition: 0.5s all ease-in-out;
    }
    h1:hover {
      color: #ffe7cb;
    }
    h4 {
      text-align: center;
      color: white;
    }
    h4:hover {
      color: #ff4800;
    }
  }

  // .contentArea {
  //   padding-top: 2rem;
  //   width: 80%;
  //   justify-content: center;
  //   display: flex;
  //   flex-direction: column;
  //   gap: 2rem;
  //   margin: auto;
  //   align-items: center;
  // }
  .contentArea {
    margin: auto;
    gap: 1rem;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    .contentLeft {
      display: flex;
      gap: 1rem;
    }
    .contentRight {
      display: flex;
      gap: 1rem;
    }

    .content {
      background-image: url(../../../assets/img/resources/resource.png);
      background-size: cover;
      width: 350px;
      height: 150px;
      .contentText {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        h1 {
          color: rebeccapurple;
          cursor: pointer;
          font-size: 1.8rem;
          margin-top: 2.5rem;
        }
        h1:hover {
          color: #f30808;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .wrapper {
    .textArea {
      width: 90% !important;
      margin-top: 1rem !important;
      padding-top: 1rem !important;
      h1 {
        font-size: 2rem;
      }
    }
    // .contentLeft {
    //   flex-direction: column;
    // }
    // .contentRight {
    //   flex-direction: column;
    // }
  }
}

@media (max-width: 1100px) {
  .wrapper {
    .contentArea {
      .contentLeft {
        flex-direction: column !important;
      }
      .contentRight {
        flex-direction: column;
      }
    }
  }
}
