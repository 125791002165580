.wrapper {


  .navArea {
    margin-top: 1rem;
  }

  .container {
    // width: 90%;
    // background-image: url("../../../../assets/bg/pageBG.png");
    // background-size: cover;
    // margin: auto;
    // background-position: center, center;
    // margin-top: 2rem;
    // padding: 5rem;
    // height: 100%;
    display: flex;
    justify-content: center;
    gap: 2rem;



    .leeftSide {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .logo {
        img {
          width: 18rem;
        }
      }

      .text {
        text-align: center;
        font-size: 20px;
      }
    }

    .rightSide {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .image {
        img {
          width: 53rem;
        }
      }

      .text {
        font-size: 16px;
        width: 53rem;
        text-align: center;
      }
    }
  }

}

@media (max-width: 800px) {
  .container {
    flex-direction: column;
    padding: 1rem !important;
  }
}

@media (max-width: 1400px) {
  .leeftSide {
    .logo {
      img {
        width: 15rem !important;
      }
    }

    .text {}
  }

  .rightSide {
    .image {
      img {
        width: 40rem !important;
      }
    }

    .text {
      font-size: 20px;
      width: 40rem !important;
    }
  }
}

@media (max-width: 1100px) {
  .leeftSide {
    .logo {
      img {
        width: 10rem !important;
      }
    }

    .text {
      font-size: 18px !important;
    }
  }

  .rightSide {
    .image {
      img {
        width: 35rem !important;
      }
    }

    .text {
      font-size: 16px !important;
      width: 35rem !important;
      padding: 5px;
      text-align: center;
    }
  }
}

@media (max-width: 845px) {
  .container {
    flex-direction: column;
    padding: 1rem !important;
  }

  .leeftSide {
    .logo {
      img {
        width: 15rem !important;
      }
    }

    .text {
      font-size: 16apx !important;
    }
  }

  .rightSide {
    align-items: center !important;
    justify-content: center !important;

    .image {
      img {
        width: 12rem !important;
      }
    }

    .text {

      font-size: 16px !important;
      width: 13rem !important;

    }
  }
}