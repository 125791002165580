.hamburgerMenu {
  display: none;
}
.menu-icon {
  display: none;
}

.wrapper ul {
  font-family: "MedievalSharp", cursive;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 60%;
  height: 10rem;
  margin: auto;
  margin-top: 1rem;
}
.navList {
  width: 8rem;
  height: 7rem;
  list-style: none;
  display: flex;
  align-items: center;
  background-image: url(../../assets/img/blood1.png);
  background-size: cover;
  background-position: center;
  text-align: center;
}
.navList a {
  text-decoration: none;
  width: 100%;
  color: rgb(255, 255, 255);
  text-align: center;

  line-height: 12px;
  font-size: 11px;
}
.text-link {
  color: inherit;
  text-decoration: inherit;
}
a:hover {
  color: rgb(250, 116, 6);
  text-decoration: none;
}
.navLink {
  color: inherit;
  text-decoration: none;
}
@media (max-width: 800px) {
  .navElement {
    display: none;
  }
  .navElement .navList {
    display: none;
  }
  .navElement .navList a {
    font-size: 17px;
  }
}
@media (max-width: 800px) {
  .navList {
    width: 5rem;
    height: 5rem;
  }
  .navList a {
    font-size: 8px;
  }
  .wrapper ul {
    width: 90%;
  }
}

@media (max-width: 800px) {
  .wrapper {
    display: flex;
    justify-content: space-around;
  }
  .menu-icon {
    display: block;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .nav-menu ul {
    font-family: "MedievalSharp", cursive;
    display: flex;
    background: #1d0303;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    /* padding: 10px; */
    width: 100%;
    height: 56%;
    margin: auto;
    gap: 5px;
    position: absolute;
    z-index: 999;
  }
  .menu-icon {
    position: absolute;
    left: 90%;
    z-index: 999;

    top: 15%;
  }
  .fa,
  .fas {
    font-size: 1.5rem;
    color: #ff2300f0;
    box-shadow: 0px 0px 7px 1px #5a0404;
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    max-height: 100vh;
    top: 3%;
  }
}
