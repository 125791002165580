.wrapper {
  background-image: url("../../../assets/bg/learningBG.png");
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-size: auto;
  .navArea {
    padding-top: 1rem;
  }
  .body {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    flex-direction: column;
    gap: 1rem;
    .content {
      background-image: url(../../../assets/img/resources/resource.png);
      background-size: cover;
      width: 350px;
      height: 150px;

      .contentText {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        h1 {
          color: rebeccapurple;
          cursor: pointer;
          font-size: 1.8rem;
          margin-top: 3.5rem;
        }
        h1:hover {
          color: #f30808;
        }
      }
    }
  }
}
@media (max-width: 800px) {
  .body {
    flex-direction: column;
  }
}
