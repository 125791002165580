.wrapper {
  width: 100%;

  background-image: url("../../../assets/bg/learningBG.png");
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-size: auto;
  .heading {
    // h1 {
    //   font-size: 5rem;
    //   text-align: center;
    //   color: rgb(252, 227, 227);
    //   cursor: pointer;
    //   transition: 0.5s all ease-in-out;
    // }
    // h1:hover {
    //   color: #ffe7cb;
    // }
    h1 {
      color: hsl(0, 0%, 28%);
      font-size: 80px;
      font-weight: bold;
      letter-spacing: 7px;
      cursor: pointer;
      text-transform: uppercase;
      text-align: center;
    }

    h1 {
      padding: 14px;
      background: linear-gradient(
        to right,
        hsl(0, 100%, 66%) 0,
        hsl(51, 100%, 50%) 10%,
        hsl(0, 97%, 63%) 20%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: shine 3s infinite linear;
    }
    @keyframes shine {
      0% {
        background-position: 0;
      }
      60% {
        background-position: 1300px;
      }
      100% {
        background-position: 100px;
      }
    }
  }
  .navArea {
    margin-top: 1rem;
  }

  .textContent {
    background-image: url("../../../assets/bg/tscs1.png");
    background-size: contain;

    width: 80%;
    margin: auto;
    margin-top: 2rem;
    .content {
      width: 80%;
      margin: auto;
      padding: 2rem !important;
      .heading {
        padding: 2rem;
        margin-left: 2rem;
        color: rgb(39, 18, 18);
      }
      .textBody {
        padding: 2rem;
        .textBox {
          margin: 10px;
          font-size: 1.5rem;
          border: 1px solid rgba(255, 0, 0, 0);
          color: rgb(29, 8, 8);
          cursor: pointer;
          padding: 1rem;
        }
        .textBox:hover {
          border: 1px solid red;
          background-color: #69070754;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .wrapper {
    .heading {
      margin-top: 38px;

      h1 {
        font-size: 32px;
        padding-top: 15px;
      }
    }
    .textContent {
      margin-top: 5px;
      .content {
        width: 95%;
        margin-top: 1rem;
        padding: 2px !important;
        .textBody {
          padding: 0;
          .textBox {
            font-size: 16px !important;
          }
        }
      }
    }
    .textBox {
      text-align: center;
      font-size: 18px !important;
    }
  }
}
