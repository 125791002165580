.wrapper {
  width: 100%;
  position: absolute;
  background-image: url("../../../assets/bg/learningBG.png");
  min-width: 100%;
  min-height: 100%;
  background-size: auto;
  .navArea {
    margin-top: 1rem;
  }

  .textArea {
    width: 100%;
    margin: auto;
    height: fit-content;

    cursor: pointer;
    // h1 {
    //   font-size: 5rem;
    //   text-align: center;
    //   color: #fce3e3;
    //   cursor: pointer;

    //   transition: 0.5s all ease-in-out;
    // }
    // h1:hover {
    //   color: #ffe7cb;
    // }
    h1 {
      color: hsl(0, 0%, 28%);
      font-size: 80px !important;
      font-weight: bold !important;
      letter-spacing: 7px !important;
      cursor: pointer;
      text-transform: uppercase;
      text-align: center;
    }
    @media (max-width: 800px) {
      h1 {
        font-size: 2.5rem !important;
      }
    }

    h1 {
      padding: 14px;
      background: linear-gradient(
        to right,
        hsl(0, 100%, 66%) 0,
        hsl(51, 100%, 50%) 10%,
        hsl(0, 97%, 63%) 20%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: shine 3s infinite linear;
    }

    @keyframes shine {
      0% {
        background-position: 0;
      }
      60% {
        background-position: 1000px;
      }
      100% {
        background-position: 600px;
      }
    }
  }

  .contentArea {
    width: 100%;
    height: fit-content;

    .mainContent {
      width: 90%;
      margin: auto;

      .content {
        margin: auto;
        width: 90%;
        margin: auto;
        margin-top: 2rem;
        display: flex;
        border: 2px solid rgba(0, 0, 0, 0);
        flex-direction: row;
        justify-content: space-around;
        height: fit-content;
        transition: 0.6s all ease-in-out;
        margin-bottom: 5rem;
        background-image: url("../../../assets/bg/learningContent.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: center;
        border-radius: 35px;

        .contentImage {
          width: 36%;
          padding: 2rem 0;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.5s ease-in-out;

          img {
            display: flex;
            justify-content: center;
            align-items: center;
            // height: 86%;
            transition: all 0.5s ease-in-out;
            width: 70%;
          }
        }

        .contentText {
          display: flex;
          flex-direction: column;
          width: 80%;
          align-items: center;
          cursor: pointer;

          justify-content: center;
          color: rgb(75, 9, 9);
          padding-right: 2rem;

          text-align: center;

          h1 {
            font-size: 3rem;
            transition: 0.3s all ease-in-out;
          }
          h6 {
            font-size: 1.5rem;
            transition: 0.3s all ease-in-out;
            width: 80%;
          }
          h1:hover {
            color: rgb(180, 82, 2);
          }
          h6:hover {
            color: rgb(180, 82, 2);
          }
        }
      }
      //   .content:hover {
      //     border: 3px solid rgba(255, 0, 0, 0.185);

      //     h1,
      //     h6 {
      //       color: rgb(27, 5, 49);
      //     }
      //   }
    }
  }
}

@media (max-width: 1200px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .contentArea {
      .mainContent {
        .content {
          .contentImage {
            width: 76%;
            padding-left: 1rem;
            img {
              padding-left: 1rem;
            }
          }
          .contentText {
            width: 80%;
            h1 {
              font-size: 2rem;
              margin-top: 1rem;
            }
            h6 {
              font-size: 1.3rem;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 900px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .contentArea {
      .mainContent {
        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .contentImage {
            width: 36%;
            padding-left: 1rem;
            margin: 0;
            padding: 0;
            img {
              padding-left: 1rem;
              padding-top: 5rem;
            }
          }
          .contentText {
            padding-right: 0rem;

            width: 95%;
            h1 {
              font-size: 1.5rem;
            }
            h6 {
              font-size: 17px;
              width: 100%;
              padding-bottom: 127px;
              padding-left: 18px;
            }
          }
        }
      }
    }
    .textArea {
      padding-top: 1rem;
      padding-bottom: 0rem;
      h1 {
        font-size: 3rem;
        padding-top: 0rem;
      }
      h6 {
        font-size: 1.5rem;
        width: 90%;
      }
    }
  }
}

@media (max-width: 600px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .contentArea {
      .mainContent {
        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .contentImage {
            width: 70%;
            padding-left: 1rem;
            margin: 0;
            padding: 0;
            img {
              padding-left: 1rem;
              width: 100%;
              padding-top: 7rem;
            }
          }
          .contentText {
            padding-right: 0rem;
            width: 95%;
            h1 {
              font-size: 1.2rem;
            }
            h6 {
              font-size: 15px;
              width: 100%;
              padding-bottom: 127px;
              padding-left: 18px;
            }
          }
        }
      }
    }
    .textArea {
      h1 {
        font-size: 30px;
        padding-top: 0rem;
        padding: 9px;
      }
      h6 {
        font-size: 1.5rem;
        width: 90%;
      }
    }
  }
}
