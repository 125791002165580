* {
  margin: 0;
  padding: 0;
  font-family: "MedievalSharp", cursive;
  /* background-image: url(../src/assets/bg/wood.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #999; */

  /* hide scrollbar for IE, Edge and Firefox */
}
/* nav {
  position: absolute;
  z-index: 1;
} */
