.wrapper {
  width: 100%;
  background-image: url("../../../assets/bg/learningBG.png");
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-size: auto;
  .navArea {
    margin-top: 1rem;
  }

  .mainDiv {
    width: 100%;

    margin: auto;

    height: fit-content;

    .textArea {
      width: 50%;
      margin: auto;

      // h1 {
      //   font-size: 5rem;
      //   text-align: center;
      //   color: rgb(252, 227, 227);
      //   cursor: pointer;
      //   transition: 0.5s all ease-in-out;
      // }
      // h1:hover {
      //   color: #ffe7cb;
      // }
      h1 {
        color: hsl(0, 0%, 28%);
        font-size: 80px !important;
        font-weight: bold !important;
        letter-spacing: 7px !important;
        cursor: pointer;
        text-transform: uppercase;
        text-align: center;
      }

      h1 {
        padding: 14px;
        background: linear-gradient(
          to right,
          hsl(0, 100%, 66%) 0,
          hsl(51, 100%, 50%) 10%,
          hsl(0, 97%, 63%) 20%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: shine 3s infinite linear;
      }
      @media (max-width: 800px) {
        h1 {
          font-size: 2.5rem !important;
        }
      }
      @keyframes shine {
        0% {
          background-position: 0;
        }
        60% {
          background-position: 570px;
        }
        100% {
          background-position: 100px;
        }
      }
      h4 {
        text-align: center;
        color: white;
      }
      h4:hover {
        color: #ff4800;
      }
    }
    .contentArea {
      margin: auto;
      padding-top: 2rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding-bottom: 3rem;

      .content {
        background-image: url(../../../assets/img/resources/resource.png);
        background-size: cover;
        width: 350px;
        height: 150px;
        .contentText {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;

          h1 {
            color: rebeccapurple;
            cursor: pointer;
            font-size: 1.8rem;
            margin-top: 3.5rem;
          }
          h1:hover {
            color: #f30808;
          }
        }
      }
    }
  }
}
@media (max-width: 800px) {
  .wrapper {
    .mainDiv {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .textArea {
    padding-top: 1rem;
    padding-bottom: 0rem;
    width: 90% !important;
    h1 {
      font-size: 30px !important;
      padding-top: 0rem;
      padding: 9px;
    }
  }
  .contentArea {
    .content {
      width: 305px !important;
      height: 134px !important;
    }
  }
}
