.wrapper {
  background-image: url("../../../assets/bg/learningBG.png");
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-size: auto;
  .navArea {
    padding-top: 1rem;
  }
  .header {
    text-align: center;
    color: white;
    width: 80%;
    margin: auto;
    margin-top: 1rem;
    padding-bottom: 2rem;
    h6 {
      font-size: 20px;
    }
  }
  .bodyContent {
    display: flex;
    flex-wrap: wrap;
    width: 72%;
    margin: auto;
    align-items: center;
    justify-content: center;
    .content {
      background-image: url(../../../assets/img/resources/resource.png);
      background-size: cover;
      width: 350px;
      height: 150px;
      .contentText {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
  }
}
.carousel-control-prev,
.carousel-control-next {
  background-color: red !important;
}
@media (max-width: 800px) {
  .header {
    padding-bottom: 1rem !important;
  }
  h6 {
    font-size: 15px !important;
  }
}
