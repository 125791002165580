.wrapper {
  width: 100%;
  height: fit-content;
  border: 1px solid rgb(255, 115, 0);
  .content {
    background-image: url(../../../assets/bg/learningBG.png);
    color: rgb(247, 210, 0);
    h3 {
      padding: 5px;
      margin: 0;
      text-align: center;
      font-size: 12px;
      background-color: rgba(36, 9, 9, 0.699);
    }
  }
}
