.wrapper {
  // width: 100%;
  // background-image: url("../../../../assets/bg/learningBG.png");
  // // position: absolute;
  // min-width: 100%;
  // min-height: 100%;
  // background-size: auto;

  .navArea {
    margin-top: 1rem;
  }

  .contentArea {
    // width: 90%;
    // background-image: url("../../../../assets/bg/pageBG.png");
    // background-size: cover;
    // margin: auto;
    // background-position: center, center;
    margin-top: 5rem;
    padding: 4rem;

    .upperContetn {

      margin: auto;
      display: flex;
      gap: 1rem;
      justify-content: space-between;
      align-items: self-start;

      .ucLeft {
        display: flex;
        flex-direction: column;
        flex-basis: 30%;
        justify-content: center;
        align-items: center;

        .ucLeftImg {
          width: 100%;
          height: auto;

          img {
            width: 15rem;
            display: flex;
            margin: auto;
          }
        }

        .ucLeftText {

          font-size: 20px !important;
          text-align: center;
        }

        .paymentBtn {
          width: 14rem;
        }
      }

      .ucRight {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-basis: 70%;
        gap: 10px;

        .ucRightImg {
          width: 100%;
          height: auto;

          img {
            width: 100%;
          }
        }

        .ucRightText {
          font-size: 16px;
          text-align: center;
        }
      }
    }

    .bottomContent {
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: self-start;
      gap: 1rem;
      margin-top: 3rem;

      .bottomLeft {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        flex-basis: 20%;

        .bottomLeftImg {
          img {
            width: 15rem;
          }
        }

        .bottomLeftText {
          text-align: center;

          span {
            font-size: 20px;
          }
        }
      }

      .bottomMiddle {

        height: auto;
        flex-basis: 33%;

        img {
          width: 100%;
          padding: 5px;
        }

      }

      .bottomRight {
        text-align: center;
        flex-basis: 35%;
        padding-left: 10px;

        span {
          text-align: center;
          font-size: 16px !important;

        }
      }
    }
  }
}






@media (max-width: 1000px) {
  .wrapper {
    .contentArea {
      margin-top: 3rem;
      padding: 0rem !important;

      .upperContetn {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .ucLeftText {
          text-align: center;
          font-weight: bold !important;

          h2 {
            font-weight: bold;

          }
        }

        .ucRightText {
          h6 {
            text-align: justify;
          }
        }
      }

      .bottomContent {
        flex-direction: column;
        justify-content: center !important;
        align-items: center !important;

        .bottomLeftText {

          span {
            font-weight: bold !important;
            font-size: 16px !important;
          }
        }

        .bottomRight {
          h6 {
            text-align: justify;
            padding-bottom: 2rem;
          }
        }

        .bottomMiddle {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .upperContetn {
    .ucLeft {

      .ucLeftImg {



        img {
          width: 29rem;
        }
      }

      .ucLeftText {}

      .paymentBtn {}
    }

    .ucRight {
      .ucRightImg {
        img {}
      }

      .ucRightText {
        font-size: 16px !important;
        padding: 5px;
        text-align: center;
      }
    }
  }

  .bottomContent {
    .bottomLeft {
      .bottomLeftImg {
        img {}
      }

      .bottomLeftText {}
    }

    .bottomMiddle {
      .bottomMiddleImg {
        img {
          width: 26rem !important;
        }
      }
    }

    .bottomRight {

      span {
        font-size: 18px !important;
      }
    }
  }
}

@media (max-width: 1100px) {
  .upperContetn {
    .ucLeft {

      .ucLeftImg {



        img {
          width: 29rem;
        }
      }

      .ucLeftText {}

      .paymentBtn {}
    }

    .ucRight {
      .ucRightImg {
        img {}
      }

      .ucRightText {}
    }
  }

  .bottomContent {
    .bottomLeft {
      .bottomLeftImg {
        img {}
      }

      .bottomLeftText {}
    }

    .bottomMiddle {
      .bottomMiddleImg {
        img {
          width: 20rem !important;
        }
      }
    }

    .bottomRight {
      padding: 5px !important;
      text-align: center;
      span {}
    }
  }
}

@media (max-width: 1200px) {
  .upperContetn {
    .ucLeft {

      .ucLeftImg {



        img {
          width: 29rem;
        }
      }

      .ucLeftText {}

      .paymentBtn {}
    }

    .ucRight {
      .ucRightImg {
        img {}
      }

      .ucRightText {}
    }
  }

  .bottomContent {
    .bottomLeft {
      .bottomLeftImg {
        img {}
      }

      .bottomLeftText {}
    }

    .bottomMiddle {
      .bottomMiddleImg {
        img {}
      }
    }

    .bottomRight {

      span {}
    }
  }
}