.wrapper {
    width: 100%;
    background-image: url("../../../assets/bg/learningBG.png");
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    background-size: auto;
    .container {
        width: 70%;
        background-image: url("../../../assets/bg/pageBG.png");
        background-size: cover;
        margin: auto;
        background-position: center, center;
        margin-top: 2rem;
        
        height: 100%;


        .firstTour{
            height: fit-content;
        }
        .secondTour {

        }
    }
}

@media (max-width: 1400px) {
    .container {
       width: 90% !important;
    }
}